<template>
<v-row no-gutters justify="center" style="margin-top:5vh">
  <v-fade-transition>
  <v-card v-show="imageReady" flat class="pa-3" width="370" max-width="370">
    <v-row no-gutters justify="center">
      <img
          id="loginImage"
            class="mx-5 px-5 pt-5 mt-5"
            style="width: 80%; max-height: 180px; object-fit: contain;"
            src="https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FNovoRPM%20Horiz.svg?alt=media&token=83350ffc-36cb-4603-9b59-d4a0dd968043"
          />
    </v-row>

    <v-row justify="center" class="font-weight-light mt-7 mb-0" style="font-size: 24px" no-gutters>
      <span>Password Reset</span>
    </v-row>
    <v-expand-transition>
    <div v-show="!emailSent">
    <v-card-text class="px-1 pb-0">
      Enter the email address that you use to log in. We'll send you an email with a link to reset your password.
    </v-card-text>
    
    </div>
    </v-expand-transition>
    <v-form ref="resetPasswordForm">
            <v-text-field class="mt-6"
              outlined
              label="Email"
              name="email"
              type="text"
              required
              v-model="email"
              :rules="emailRules"
              v-on:keyup.enter="sendReset()"
            />
          </v-form>
          <v-expand-transition>
    <div v-show="emailSent">
      <v-card-text class="mt-0 pt-0 px-0">
        The password reset email has been sent. Please check your email and follow the instructions to reset your password. Once you've reset your password. Return to the login screen to enter your new password.
      </v-card-text>
    </div>
          </v-expand-transition>
          <v-row justify="space-between" no-gutters>
          <v-btn
            color="primary"
            text
            @click="switchToLogin"
            >Back to Login</v-btn
          >
          <v-spacer />
          <v-btn color="primary" depressed @click="sendReset">
            <v-fade-transition hide-on-leave>
            <span v-show="!resetLoading">
            <span v-if="emailSent">Resend</span>
            <span v-else>Send</span>
            </span>
            </v-fade-transition>
            <v-fade-transition hide-on-leave>
            <v-progress-circular v-show="resetLoading" size="20" indeterminate/>
            </v-fade-transition>
            </v-btn>
        </v-row>
  </v-card>
  </v-fade-transition>
</v-row>
</template>

<script>
import { mapActions } from 'vuex'
import bus from '@/core/helpers/bus'
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [HelperMixin],
  name: 'resetpwpage',
  components: {},
  data() {
    return {
      imageReady: false,
      resetLoading: false,
      email: null,
      emailRules: [v => !!v || 'E-mail is required'],
      emailSent: false
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    tagline() {
      return 'Powered by OneChart Health'
    }
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    sendReset() {
      if (this.$refs.resetPasswordForm.validate()) {
        this.resetLoading = true
        this.resetPassword(this.email).then(() => {
          bus.$emit('toast', { type: 'success', text: 'Email Sent' })
          this.emailSent = true
          this.resetLoading = false
        })
        .catch(error => {
        bus.$emit('toast', { type: 'error', text: 'Error Sending Email' })
        this.resetLoading = false
        return error
      })
      }
    },
    switchToLogin() {
      this.$router.push({ name: 'Login', query: this.$route.query })
    },
  },
  mounted() {
    let img = document.getElementById('loginImage')
    const vm = this
    img.onload = function() {
      vm.imageReady = true
    };

    img.onerror = function() {
      vm.imageReady = true
    };
  }
}
</script>
